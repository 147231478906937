import React, { useEffect, useState } from "react";
import { fetchDataByType } from "../api/api";

const Footer = () => {

  const [complaintReportContact, setComplaintReportContact] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      const data = await fetchDataByType("semyorka");
      if (data) {
        setComplaintReportContact(data.complaintReportContact);
      }
    };

    fetchInitialData();
  }, []);

  return (
    <div className="footer-container flex-column">
      <div>
        <a
          href={`https://wa.me/${complaintReportContact}`}
          className="btn btn-lg btn-danger col-12 col-sm-5 mb-2 mt-3"
        >
          Şikayətinizi bildirin
        </a>
      </div>
      <div className="container-fluid d-flex justify-content-around p-2 mt-3">
        {" "}
        <h5 className="logo">SEMYORKA CASİNO</h5>
        <h6>7/24</h6>
      </div>
    </div>
  );
};

export default Footer;
